/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import APIService from "../../service/APIService";
import moment from "moment";
import { priceFormat } from "../../utils";

// Function to check if a date is today
const isToday = (inputDate) => {
    const today = new Date();
    const input = new Date(inputDate);
    return (
        today.getFullYear() === input.getFullYear() &&
        today.getMonth() === input.getMonth() &&
        today.getDate() === input.getDate()
    );
};

// Function to check if a date is in the current week
const isThisWeek = (inputDate) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 6));

    const input = new Date(inputDate);
    return input >= startOfWeek && input <= endOfWeek;
};

export default function ThongKePaymentPage() {
  const { Title } = Typography;
  const [dataTable, setDataTable] = useState([]);

  const columns = [
    {
      title: "THỜI GIAN",
      dataIndex: "days",
      key: "days",
      width: 140,
      align: "center",
    },
    {
      title: "LỢI NHUẬN",
      dataIndex: "doanhthu",
      key: "doanhthu",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP THẺ CÀO",
      dataIndex: "Nạp Card",
      key: "Nạp Card",
      width: 170,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI THẺ CÀO",
      dataIndex: "Đổi Card",
      key: "Đổi Card",
      width: 170,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP NGÂN HÀNG",
      dataIndex: "Nạp Bank",
      key: "Nạp Bank",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI NGÂN HÀNG",
      dataIndex: "Đổi Bank",
      key: "Đổi Bank",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP MOMO",
      dataIndex: "Nạp Momo",
      key: "Nạp Momo",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI MOMO",
      dataIndex: "Đổi Momo",
      key: "Đổi Momo",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
  ];

  async function getStatisGoldList() {
    try {
      const now = moment();
      const isSunday = now.isoWeekday() === 7;
      const currentDay = now.format("YYYY-MM-DD");
      // Tính toán ngày bắt đầu và kết thúc tuần tương ứng
      const startOfWeek = isSunday
        ? now.clone().subtract(6, "days")
        : now.clone().startOf("isoWeek");
      const endOfWeek = isSunday ? now.clone() : now.clone().endOf("isoWeek");
      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone().endOf("month");
      let timeFrom = startOfMonth.format("YYYY-MM-DD");
      if (startOfMonth > startOfWeek)
        timeFrom = startOfWeek.format("YYYY-MM-DD");
      let timeTo = endOfMonth.format("YYYY-MM-DD");
      const data = await APIService.statisGoldListDraw(timeFrom, timeTo);
      if (data) {
        if (data.type === 1 || true) {
          let key = {
            nap_card: "Nạp Card",
            doi_card: "Đổi Card",
            nap_bank: "Nạp Bank",
            doi_bank: "Đổi Bank",
            nap_momo: "Nạp Momo",
            doi_momo: "Đổi Momo",
          };
          let arrData = [];
          let doanhthuDay = {};
          let tongtienDay = 0;
          let tongdoanhthuDay = 0;
          doanhthuDay[key.nap_card] = 0;
          doanhthuDay[key.doi_card] = 0;
          doanhthuDay[key.nap_bank] = 0;
          doanhthuDay[key.doi_bank] = 0;
          doanhthuDay[key.nap_momo] = 0;
          doanhthuDay[key.doi_momo] = 0;
          let doanhthuWeek = {};
          let tongtienWeek = 0;
          let tongdoanhthuWeek = 0;
          doanhthuWeek[key.nap_card] = 0;
          doanhthuWeek[key.doi_card] = 0;
          doanhthuWeek[key.nap_bank] = 0;
          doanhthuWeek[key.doi_bank] = 0;
          doanhthuWeek[key.nap_momo] = 0;
          doanhthuWeek[key.doi_momo] = 0;
          let doanhthuMonth = {};
          let tongtienMonth = 0;
          let tongdoanhthuMonth = 0;
          doanhthuMonth[key.nap_card] = 0;
          doanhthuMonth[key.doi_card] = 0;
          doanhthuMonth[key.nap_bank] = 0;
          doanhthuMonth[key.doi_bank] = 0;
          doanhthuMonth[key.nap_momo] = 0;
          doanhthuMonth[key.doi_momo] = 0;
          for(let _k in key){
            doanhthuMonth[key[_k]] = data.data.filter(i=>i.game===key[_k])
                                                  .map(i=>i.sumGold).reduce((i,v)=>i+v,0);
            tongdoanhthuMonth+=doanhthuMonth[key[_k]];

            doanhthuDay[key[_k]] = data.data.filter(i=>i.game===key[_k]&&isToday(i.days))
                                                  .map(i=>i.sumGold).reduce((i,v)=>i+v,0);
            tongdoanhthuDay+=doanhthuDay[key[_k]];

            doanhthuWeek[key[_k]] = data.data.filter(i=>i.game===key[_k]&&isThisWeek(i.days))
                                                  .map(i=>i.sumGold).reduce((i,v)=>i+v,0);
            tongdoanhthuWeek+=doanhthuWeek[key[_k]];
          }
          console.log(doanhthuMonth,'doanhthuMonth')
          if(false) {// code bo
            data.array.forEach((element) => {
              if (element.days === currentDay) {
                tongtienDay += element[key.nap_card];
                doanhthuDay[key.nap_card] += Math.round(
                  0.79 * element[key.nap_card]
                );
                tongtienDay += element[key.doi_card];
                doanhthuDay[key.doi_card] += element[key.doi_card];
                tongtienDay += element[key.nap_bank];
                doanhthuDay[key.nap_bank] += Math.round(
                  0.97 * element[key.nap_bank]
                );
                tongtienDay += element[key.doi_bank];
                doanhthuDay[key.doi_bank] += Math.round(
                  1.03 * element[key.doi_bank]
                );
                tongtienDay += element[key.nap_momo];
                doanhthuDay[key.nap_momo] += Math.round(
                  0.97 * element[key.nap_momo]
                );
                tongtienDay += element[key.doi_momo];
                doanhthuDay[key.doi_momo] += Math.round(
                  1.03 * element[key.doi_momo]
                );
                tongdoanhthuDay += Math.round(0.79 * element[key.nap_card]);
                tongdoanhthuDay += element[key.doi_card];
                tongdoanhthuDay += Math.round(0.97 * element[key.nap_bank]);
                tongdoanhthuDay += Math.round(1.03 * element[key.doi_bank]);
                tongdoanhthuDay += Math.round(0.97 * element[key.nap_momo]);
                tongdoanhthuDay += Math.round(1.03 * element[key.doi_momo]);
              }
              let date = moment(element.days, "YYYY-MM-DD").toDate();
              if (startOfWeek <= date && date <= endOfWeek) {
                tongtienWeek += element[key.nap_card];
                doanhthuWeek[key.nap_card] += Math.round(
                  0.79 * element[key.nap_card]
                );
                tongtienWeek += element[key.doi_card];
                doanhthuWeek[key.doi_card] += element[key.doi_card];
                tongtienWeek += element[key.nap_bank];
                doanhthuWeek[key.nap_bank] += Math.round(
                  0.97 * element[key.nap_bank]
                );
                tongtienWeek += element[key.doi_bank];
                doanhthuWeek[key.doi_bank] += Math.round(
                  1.03 * element[key.doi_bank]
                );
                tongtienWeek += element[key.nap_momo];
                doanhthuWeek[key.nap_momo] += Math.round(
                  0.97 * element[key.nap_momo]
                );
                tongtienWeek += element[key.doi_momo];
                doanhthuWeek[key.doi_momo] += Math.round(
                  1.03 * element[key.doi_momo]
                );
                tongdoanhthuWeek += Math.round(0.79 * element[key.nap_card]);
                tongdoanhthuWeek += element[key.doi_card];
                tongdoanhthuWeek += Math.round(0.97 * element[key.nap_bank]);
                tongdoanhthuWeek += Math.round(1.03 * element[key.doi_bank]);
                tongdoanhthuWeek += Math.round(0.97 * element[key.nap_momo]);
                tongdoanhthuWeek += Math.round(1.03 * element[key.doi_momo]);
              }
              if (startOfMonth <= date && date <= endOfMonth) {
                tongtienMonth += element[key.nap_card];
                doanhthuMonth[key.nap_card] += Math.round(
                  0.79 * element[key.nap_card]
                );
                tongtienMonth += element[key.doi_card];
                doanhthuMonth[key.doi_card] += element[key.doi_card];
                tongtienMonth += element[key.nap_bank];
                doanhthuMonth[key.nap_bank] += Math.round(
                  0.97 * element[key.nap_bank]
                );
                tongtienMonth += element[key.doi_bank];
                doanhthuMonth[key.doi_bank] += Math.round(
                  1.03 * element[key.doi_bank]
                );
                tongtienMonth += element[key.nap_momo];
                doanhthuMonth[key.nap_momo] += Math.round(
                  0.97 * element[key.nap_momo]
                );
                tongtienMonth += element[key.doi_momo];
                doanhthuMonth[key.doi_momo] += Math.round(
                  1.03 * element[key.doi_momo]
                );
                tongdoanhthuMonth += Math.round(0.79 * element[key.nap_card]);
                tongdoanhthuMonth += element[key.doi_card];
                tongdoanhthuMonth += Math.round(0.97 * element[key.nap_bank]);
                tongdoanhthuMonth += Math.round(1.03 * element[key.doi_bank]);
                tongdoanhthuMonth += Math.round(0.97 * element[key.nap_momo]);
                tongdoanhthuMonth += Math.round(1.03 * element[key.doi_momo]);
              }
            });
          } 
          doanhthuDay["id"] = 1;
          doanhthuDay["days"] = "Hôm nay";
          doanhthuDay["doanhthu"] = tongdoanhthuDay;
          arrData.push(doanhthuDay);
          doanhthuWeek["id"] = 2;
          doanhthuWeek["days"] = "Tuần này";
          doanhthuWeek["doanhthu"] = tongdoanhthuWeek;
          arrData.push(doanhthuWeek);
          doanhthuMonth["id"] = 3;
          doanhthuMonth["days"] = "Tháng này";
          doanhthuMonth["doanhthu"] = tongdoanhthuMonth;
          arrData.push(doanhthuMonth);
          setDataTable(arrData);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStatisGoldList();
  }, []);

  return (
    <>
      <br></br>
      <Typography>
        <Title level={4}>&nbsp;&nbsp;&nbsp;&euro; THỐNG KÊ LỢI NHUẬN</Title>
      </Typography>
      <br></br>
      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={false} />
    </>
  );
}
