import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Table,
  notification,
  Input,
  Drawer,
  Tooltip,
} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import {  faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIService from "../../service/APIService";
import _ from "lodash";
import moment from "moment";
const { TextArea } = Input;

export default function UserMailPage() {
  const [dataTable, setDataTable] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [filter, setFilter] = useState({
    player: "",
  });
  const [form] = Form.useForm();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",

    },
    {
      title: "NGƯỜI GỬI",
      dataIndex: "sender",
      key: "sender",
      align: "center",
    },
    {
      title: "GỬI LÚC",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 190,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data?.substring(0, 10)).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "TIÊU ĐỀ",
      dataIndex: "title",
      key: "title",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "NỘI DUNG",
      dataIndex: "content",
      key: "content",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      align: "center",
    },
    {
      title: "ĐÃ NHẬN",
      dataIndex: "isClaim",
      key: "isClaim",
      align: "center",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center",
      render(data){
        return <Button className={'ant-btn-dangerous'} onClick={()=>onDeleteMail(data)}>Xoá</Button>
      }
    },
  ];

  async function onDeleteMail(id){
    if(!id){
      return;
    }

    const isConfirm = window.confirm("Xoá User Mail ID: " + id);

    if(!isConfirm){
      return;
    }
    try {
      const data = await APIService.deleteMail(id);
      if (data) {
        notification["success"]({
          message: "Thông Báo",
          description: "Đã xoá thành công",
        });
        setDataTable(dataTable.filter(i=>i['id']!==+id))
      }
    } catch (error) {
      notification["error"]({
        message: "Lỗi",
        description: "Không thể xoá",
      });
    }
  }

  async function getMailList() {
    try {
      const data = await APIService.mailList(filter.player.trim());
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCreateMail(player, title, content) {
    try {
      const data = await APIService.createMail(player, title, content);
      if (data) {
        console.log(data);
        notification['success']({
          message: 'Thành công',
          description:
            'Mail đã được tạo.',
        });
        getMailList()
        form.resetFields();
        setOpenCreate(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getMailList();
  }, [filter]);

  const onFinish = (values) => {
    getCreateMail(values.player, values.title, values.content);
  };

  return (
    <>
      <FormSearchFilter
      title="Hộp thư"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          setFilter({ ...filter, ...value });
        }}
      >
        <Form.Item label="Player ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <div className="flex justify-end items-end  col-span-3 pb-1">
          <Button
            onClick={() => {
              setOpenCreate(true);
            }}
            className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6"
          >
            TẠO MAIL
          </Button>
        </div>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
      <Drawer
        closable={false}
        placement="right"
        onClose={() => {
          setOpenCreate(false);
        }}
        open={openCreate}
        width={550}
        bodyStyle={{ padding: 0 }}
      >
        <div className="py-3 px-5 flex justify-between items-center bg-[#F8F8F8]">
          Thông tin tạo mới
          <Button
            type="link"
            onClick={() => {
              setOpenCreate(false);
            }}
          >
            <FontAwesomeIcon
              icon={faXmark}
              style={{ fontSize: "18px", color: "black" }}
            />
          </Button>
        </div>
        <div className="p-5">
          <Form
            layout="vertical"
            form={form}
            className="flex w-full  form-search-filer flex-col "
            onFinish={onFinish}
          >
            <Form.Item
              label={<p className="font-bold">Player ID</p>}
              name="player"
            >
              <Input className="w-full flex-1" placeholder="Để trổng để gửi tất cả. Có thể gửi nhiều ngăn cách bởi dấu phẩy" />
            </Form.Item>
            <Form.Item label={<p className="font-bold ">Title</p>} name="title"  rules={[{ required: true, message: 'Vui lòng không để trống' }]}>
              <Input className="w-full flex-1" />
            </Form.Item>
            <Form.Item
              label={<p className="font-bold ">Content</p>}
              rules={[{ required: true, message: 'Vui lòng không để trống' }]}
              name="content"
            >
              <TextArea rows={4} className="w-full flex-1" />
            </Form.Item>
            <Form.Item>
            <div className="flex flex-1 justify-end">
            <Button
              htmlType="submit"
              className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
            >
              TẠO MAIL
            </Button>
          </div>
          </Form.Item>
          </Form>

        </div>
      </Drawer>
    </>
  );
}
